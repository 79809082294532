import ApiService from "./ApiService"

export async function assignOrdesToEmployee (data) {
    return ApiService.fetchData({
        url: '/orders/assignOrdesToEmployee/'+data._id+'/'+data.isPrepaid,
        method: 'get',
    })
}

export async function listEmployeeOrders (data) {
    return ApiService.fetchData({
        url: '/orders/listEmployeeOrders/'+data._id,
        method: 'get',
    })
}

export async function listOrderType (data) {
    return ApiService.fetchData({
        url: '/orders/listOrderType',
        method: 'post',
        data
    })
}

export async function listPendingOrders (data) {
    return ApiService.fetchData({
        url: '/orders/listPendingOrders/' + data._id,
        method: 'get',
    })
}
export async function fetchPreviousOrder (data) {
    return ApiService.fetchData({
        url: '/orders/fetchPreviousOrder/' + data.Date +'/'+ data.BrandID,
        method: 'get',
    })
}

export async function generateWayBill (data) {
    return ApiService.fetchData({
        url: '/orders/generateWayBill/',
        method: 'post',
        data
    })
}

export async function editOrder (data) {
    return ApiService.fetchData({
        url: '/orders/editOrder',
        method: 'post',
        data
    })
}

export async function listAllOrders (data) {
    return ApiService.fetchData({
        url: '/orders/listAllOrders',
        method: 'post',
        data
    })
}

export async function updateOrderStatus (data) {
    return ApiService.fetchData({
        url: '/orders/updateOrderStatus',
        method: 'post',
        data
    })
}

export async function listOrderStatistics (data) {
    return ApiService.fetchData({
        url: '/orders/listOrderStatistics',
        method: 'post',
        data
    })
}



